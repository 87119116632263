import React from 'react';
import styled, { css } from 'styled-components';
import { withTheme as withMuiTheme } from '@material-ui/core/styles';
import * as materialColors from '@material-ui/core/colors';
import { omit } from 'lodash';

const colorAlias = {
  facebook: '#3B5998',
};

export const withTheme = withMuiTheme;
export function cleanProps(props, approvedProps = []) {
  const res = approvedProps.reduce((a, n) => {
    return n && props[n]
      ? {
          ...a,
          [n]: props[n],
        }
      : a;
  }, {});
  return res;
}

export function withProps(providedProps, Wrapped) {
  return (...props) => <Wrapped {...providedProps} {...props} />;
}

export function withDefaultProps(defaultProps, Wrapped) {
  const WithDefaultPropsComponent = (props) => (
    <Wrapped
      {...{
        ...defaultProps,
        ...props,
      }}
    />
  );
  return WithDefaultPropsComponent;
}

function sizeStyles(property, styles) {
  return (
    styles &&
    ((props) => {
      const result = [];
      if (Array.isArray(styles)) {
        const breakpoints = Object.values(props.theme.breakpoints.values);
        // Media Query
        result.push(
          ...styles.map(
            (val, key) =>
              val &&
              `
        @media(min-width: ${breakpoints[key] || 0}px) {
          ${property}: ${Number.isNaN(val.toString().substr(-1)) ? val : `${val}px`};
        }
      `,
          ),
        );
      } else {
        result.push(`${property}: ${Number.isNaN(styles.toString().substr(-1)) ? styles : `${styles}px`}`);
      }
      return result.join('\n');
    })
  );
}

export function spacing(key) {
  // eslint-disable-next-line complexity
  return (props) => {
    const config = {};
    if (key === 'padding') {
      config.t = props.p || props.py || props.pt || null;
      config.r = props.p || props.px || props.pr || null;
      config.b = props.p || props.py || props.pb || null;
      config.l = props.p || props.px || props.pl || null;
    } else {
      config.t = props.m || props.my || props.mt || null;
      config.r = props.m || props.mx || props.mr || null;
      config.b = props.m || props.my || props.mb || null;
      config.l = props.m || props.mx || props.ml || null;
    }

    return css`
      ${sizeStyles(`${key}-top`, config.t)};
      ${sizeStyles(`${key}-right`, config.r)};
      ${sizeStyles(`${key}-bottom`, config.b)};
      ${sizeStyles(`${key}-left`, config.l)};
    `;
  };
}

export const padding = spacing('padding');
export const margin = spacing('margin');

export function space() {
  const result = css`
    ${padding};
    ${margin};
  `;
  return result;
}

export function cssValue(key, value) {
  return (
    value &&
    css`
      ${key}: ${value};
    `
  );
}

export function minHeight(props) {
  return cssValue('min-height', props.minHeight);
}

export function minWidth(props) {
  return cssValue('min-width', props.minWidth);
}

export function textAlign(props) {
  return cssValue('text-align', props.textAlign);
}

export function width(props) {
  return cssValue('width', props.width);
}

export function height(props) {
  return cssValue('height', props.height);
}

export function lineHeight(props) {
  return cssValue('line-height', props.lineHeight);
}

export function fontSize(props) {
  return cssValue('font-size', props.fontSize);
}

export function display(props) {
  if (!Object.keys(props).includes('display', 'inline', 'block', 'inline-block', 'flex', 'inline-flex')) {
    return '';
  }

  return cssValue('display', (props.block && 'block') || (props.inlineFlex && 'inlineFlex') || props.display);
}

export function shadow(props) {
  return (
    props.shadow &&
    css`
      box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.03);
    `
  );
}

export function bg(props) {
  if (!props.bg) {
    return '';
  }

  const [clr, hue = 500] = (colorAlias[props.bg] || props.bg).split(' ');
  return css`
    background-color: ${materialColors[clr] ? materialColors[clr][hue] : clr};
  `;
}

export function color(props) {
  if (!props.color) {
    return '';
  }

  const [clr, hue = 500] = (colorAlias[props.color] || props.color).split(' ');
  return css`
    color: ${materialColors[clr] ? materialColors[clr][hue] : clr};
  `;
}

export function borderRadius(props) {
  return cssValue('border-radius', props.borderRadius);
}

export function borderColor(props) {
  return cssValue('border-color', props.borderColor);
}

export function borderWidth(props) {
  return cssValue('border-width', props.borderWidth);
}

export const omitDefaults = [
  'theme',
  'mt',
  'mb',
  'mr',
  'ml',
  'mx',
  'my',
  'p',
  'pt',
  'pl',
  'pr',
  'px',
  'py',
  'fontSize',
  'color',
  'fullWidth',
  'float',
];

export function tailored(Wrapped, styles = '', propNames = []) {
  return styled((props) => <Wrapped {...cleanProps(props, [...propNames, 'children', 'className'])} />)`
    ${styles};
    ${space};
    ${minWidth};
    ${minHeight};
    ${textAlign};
    ${width};
    ${height};
    ${lineHeight};
    ${fontSize};
    ${display};
    ${shadow};
    ${bg};
    ${color};
    ${borderRadius};
    ${borderColor};
    ${borderWidth};
  `;
}

export const cleanComponentProps = (ComposedComponent, propsToOmit = []) => (
  <ComposedComponent {...omit(this.props, propsToOmit)} />
);
export default cleanComponentProps;
