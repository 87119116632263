import mitt from 'mitt';
import { clientSideTokenProvider } from './token-provider';
import { Authenticator } from './authenticator';

export class AuthClient {
  userProvider;
  authenticator;
  emitter;
  logger;
  _user = false;
  _promise = null;

  constructor(authClientConfiguration) {
    this.userProvider = authClientConfiguration.userProvider;
    this.authenticator = new Authenticator({
      customerWebsiteBaseUrl: authClientConfiguration.customerWebsiteBaseUrl,
      tokenProvider: authClientConfiguration.tokenProvider || clientSideTokenProvider,
    });
    this.emitter = authClientConfiguration.emitter || mitt();
    this.logger = console;
  }

  async user(forceLoad = false) {
    this.logger.info(`AuthClient:user called forceLoad: ${forceLoad}`);
    if (!forceLoad && this._promise instanceof Promise) {
      return this._promise;
    } else if (forceLoad || !this._user) {
      this._promise = this.fetchProfile()
        .then((user) => {
          if (user) {
            this._user = user;
          }
          return user;
        })
        .catch((error) => {
          this.logger.error('AuthClient.user(): failed to fetch user', error);
          return false;
        });
      return this._promise;
    }
    return Promise.resolve(this._user);
  }

  async fetchProfile() {
    this.logger.info(`AuthClient:fetchProfile called...`);
    const authentication = await this.authenticator.getAuthentication();
    if (authentication.idToken) {
      return this.userProvider.getUser(authentication.customerId, authentication.idToken);
    }
    return false;
  }

  async isAuthenticated() {
    this.logger.info(`AuthClient:isAuthenticated called...`);
    try {
      await this.authenticator.getAuthentication();
      return true;
    } catch {
      return false;
    }
  }

  isTrusted() {
    this.logger.info(`AuthClient:isTrusted called...`);
    if (this._user instanceof Promise) {
      return false;
    }
    return (
      this._user && this._user.trusted && this._user.status_sms === 'verified' && this._user.status_email === 'verified'
    );
  }

  async grant({ returnUser = false, redirectMessage = '' } = {}) {
    this.logger.info(`AuthClient:grant called... returnUser: ${returnUser}, redirectMessage: ${redirectMessage}`);
    if (this._user instanceof Promise) {
      return { success: false };
    }
    return { success: await this.isAuthenticated() };
  }
  // The remaining functions were on the legacy auth client, but  are
  // not used by PLS or Self Show
  isVerified() {
    this.logger.error(`AuthClient:isVerified called...`);
    throw new Error('Unsupported Operation: isVerified');
  }
  beginVerification(what, data) {
    this.logger.error(`AuthClient:beginVerification called... what: ${what}, data: ${data}`);
    throw new Error('Unsupported Operation: beginVerification');
  }
  completeVerification(what, token, data) {
    this.logger.error(`AuthClient:completeVerification called... what: ${what}, token: ${token}, data: ${data}`);
    throw new Error('Unsupported Operation: completeVerification');
  }
  assertAuthenticated(message) {
    this.logger.error(`AuthClient:assertAuthenticated called... message: ${message}`);
    throw new Error('Unsupported Operation: assertAuthenticated');
  }
  login(payload = {}) {
    this.logger.error(`AuthClient:login called... payload: ${payload}`);
    throw new Error('Unsupported Operation: login');
  }
  logout() {
    this.logger.error(`AuthClient:logout called...`);
    throw new Error('Unsupported Operation: logout');
  }
  redirect(options = {}) {
    this.logger.error(`AuthClient:redirect called... options: ${JSON.stringify(options)}`);
    throw new Error('Unsupported Operation: redirect');
  }
  register(options) {
    this.logger.error(`AuthClient:register called... options: ${JSON.stringify(options)}`);
    throw new Error('Unsupported Operation: register');
  }
  resendVerification(what, data) {
    this.logger.error(`AuthClient:resendVerification called... what: ${what}, data: ${JSON.stringify(data)}`);
    throw new Error('Unsupported Operation: resendVerification');
  }
  resetPassword(data, options) {
    this.logger.error(
      `AuthClient:resetPassword called... data: ${JSON.stringify(data)}, options: ${JSON.stringify(options)}`,
    );
    throw new Error('Unsupported Operation: resetPassword');
  }
  startPolling(interval) {
    this.logger.error(`AuthClient:startPolling called... interval: ${interval}`);
    throw new Error('Unsupported Operation: startPolling');
  }
  store(grant, user) {
    this.logger.error(`AuthClient:store called... grant: ${JSON.stringify(grant)}, user: ${JSON.stringify(user)}`);
    throw new Error('Unsupported Operation: store');
  }
  updateLocalUser(user) {
    this.logger.error(`AuthClient:updateLocalUser called... user: ${JSON.stringify(user)}`);
    throw new Error('Unsupported Operation: updateLocalUser');
  }
  updateProfile(data, options) {
    this.logger.error(
      `AuthClient:updateProfile called... data: ${JSON.stringify(data)}, options: ${JSON.stringify(options)}`,
    );
    throw new Error('Unsupported Operation: updateProfile');
  }
}
