import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment-timezone';

import Days from '../containers/Days';
import Times from '../containers/Times';

import Grid from '@material-ui/core/Grid';
import { Button } from '@invitation-homes/styled-ui/lib/components/Button/Button';
import { withEmitter } from '@invitation-homes/styled-ui/lib/providers/Emitter';
import { FormSection } from '@invitation-homes/styled-ui/lib/components/Form/Section';
import CircleLoader from '@invitation-homes/styled-ui/lib/components/Loader/CircleLoader';

import { Heading, PageHeading } from '../components/CardCommon';
import styled from 'styled-components';
import ErrorMessage from '../components/ErrorMessage';

import { canModifyShowing } from '../../lib/showing-process/can-modify-showing';
import SelfTourHeader from '../components/SelfTourHeader';

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 545px) {
    flex-direction: column-reverse;
  }
`;

const WhiteGrid = styled(Grid)`
  padding-top: 10px;
  padding-right: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
  background-color: white;
`;

class ShowingEditComponent extends Component {
  state = {
    timeSelected: null,
    daySelected: null,
    available: [],
    timezone: null,
    loading: true,
    dirty: false,
    saving: false,
    error: false,
  };
  componentDidMount() {
    axios
      .get(`/api/v1/showing/${this.props.match.params.id}`)
      .then((response) => response.data)
      .then((showing) => {
        // redirect if showing should not be changeable
        const changeable = canModifyShowing(showing);
        if (!changeable) {
          this.props.history.push(`/home/${this.props.match.params.id}`);
        }
        // get availability
        const unit_id = showing.unit_id;
        this.unit_id = unit_id;
        this.getAvailability((data) => {
          this.setState({
            available: data.available,
            timezone: data.unitTimezone,
            daySelected: moment(showing.date),
            timeSelected: {
              start: moment(showing.start_time),
              end: moment(showing.end_time),
            },
            loading: false,
          });
        });
      })
      .catch((err) => {
        this.props.emitter.emit('notification', {
          type: 'error',
          message: 'We’re sorry, an error occurred on our end. Please try back again later.',
        });

        this.setState({ error: err, loading: false });
      });
  }
  getAvailability(callback) {
    axios
      .get(`/api/v1/availability?unit_id=${this.unit_id}`)
      .then((response) => response.data)
      .then(callback)
      .catch((err) => {
        this.props.emitter.emit('notification', {
          type: 'error',
          message: 'We’re sorry, an error occurred on our end. Please try back again later.',
        });

        this.setState({
          error: err,
        });
      });
  }

  onDaySelect = (daySelected) => {
    this.setState({ daySelected, dirty: true });
  };

  onTimeSelected = (timeSelected) => {
    this.setState({ timeSelected, dirty: false });
  };

  onSave = () => {
    const { timeSelected, daySelected } = this.state;
    this.setState({ saving: true });
    axios
      .put(`/api/v1/showing/${this.props.match.params.id}`, {
        date: daySelected,
        start_time: timeSelected.start,
        end_time: timeSelected.end,
      })
      .then(() => {
        this.props.history.push(`/home/${this.props.match.params.id}`);
      })
      .catch(() => {
        this.setState({
          error: 'error',
        });
      });
  };
  render() {
    const { available, timezone, timeSelected, daySelected, loading, error, dirty, saving } = this.state;

    const disabledButton = saving ? true : dirty;
    return (
      <Grid container xs={12} direction="row" alignItems="flex-start" justify="center">
        <Grid item xs={12} lg={10}>
          <SelfTourHeader />
        </Grid>
        <WhiteGrid item xs={12} lg={10} bg="white" pt={10} pr={40} pb={40} pl={40}>
          <PageHeading>Edit My Self-Tour</PageHeading>
          {/* eslint-disable-next-line no-nested-ternary */}
          {error ? (
            <ErrorMessage message="_" />
          ) : loading || saving ? (
            <CircleLoader size={24} />
          ) : (
            <div>
              <FormSection>
                <Heading>Select A Day</Heading>
                <Days
                  initialNumShown={4}
                  showAll={daySelected && daySelected.diff(moment().startOf('day'), 'day') > 4}
                  onSelect={this.onDaySelect}
                  selected={daySelected}
                  available={available}
                  timezone={timezone}
                />
              </FormSection>
              {daySelected && (
                <FormSection>
                  <Heading>Select A Time</Heading>
                  <Times
                    onSelect={this.onTimeSelected}
                    selectedTime={timeSelected}
                    selectedDay={daySelected}
                    available={available}
                    timezone={timezone}
                  />
                </FormSection>
              )}
              <ButtonContainer>
                <Button
                  to={`/home/${this.props.match.params.id}`}
                  variant="outlined"
                  color="primary"
                  mt={10}
                  pt={10}
                  pb={10}
                  style={{ height: '40px', width: '150px' }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={this.onSave}
                  disabled={disabledButton}
                  color="primary"
                  variant="contained"
                  mt={10}
                  pt={10}
                  pb={10}
                  style={{ height: '40px', width: '150px' }}
                >
                  Save Changes
                </Button>
              </ButtonContainer>
            </div>
          )}
        </WhiteGrid>
      </Grid>
    );
  }
}
export default withEmitter(withRouter(ShowingEditComponent));
